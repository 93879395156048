import React, { useEffect, useState } from "react";
import { useTheme } from "../components/Theme/Themecontext";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";



function MeetingCard({ meetingInfos, key, refresh, styleKey, isLoading }) {
  const { t, i18n } = useTranslation();

  const { styles } = useTheme();
  const { component, blacktext } = styles;
  const dynamicStyle = styles[styleKey];

 
  const [meetingInfo, setMeetingInfo] = useState(meetingInfos);

  useEffect(() => {
    setMeetingInfo(meetingInfos);
  }, [meetingInfos]);

  const truncateText = (text, wordCount) => {
    if (!text) {
      return "";
    }

    const words = text.split(" ");
    if (words.length <= wordCount) return text;
    return words.slice(0, wordCount).join(" ") + "...";
  };

  const formatTime = (timeString) => {
    if (!timeString) return "00:00";
    const time = new Date(timeString);
    let hours = time.getHours();
    let minutes = time.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    const formattedTime = hours + ":" + minutes + " " + ampm;
    return formattedTime;
  };

  
  return (
    <div
      style={component}
      className={`rounded-lg xl:gap-10 w-full ${
        i18n.language === "ar" ? "lg:justify-end" : "lg:justify-start"
      }`}
    >
      <Link
        key={meetingInfo?.meetingId}
        to={`/home/meeting/details?meetingId=${meetingInfo?.meetingId}`}
        className="w-full"
      >
        <div style={dynamicStyle} className="rounded-xl w-full p-2">
          <div className="flex-cols items-center ">
            <div className="flex items-center justify-between text-[14px] mb-3">
              <h1
                style={blacktext}
                className="w-[135px] min-[425px]:w-[160px] xl:w-[200px] break-all text-[14px] md:text-[16px] font-medium md:font-semibold"
              >
                <span>{meetingInfo?.title}</span>
                {/* <span onClick={truncateText}>{`>>`}</span> */}
              </h1>
              <div className="flex items-center gap-1">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.00016 0.333252C3.32683 0.333252 0.333496 3.32659 0.333496 6.99992C0.333496 10.6733 3.32683 13.6666 7.00016 13.6666C10.6735 13.6666 13.6668 10.6733 13.6668 6.99992C13.6668 3.32659 10.6735 0.333252 7.00016 0.333252ZM9.90016 9.37992C9.80683 9.53992 9.64016 9.62659 9.46683 9.62659C9.38016 9.62659 9.2935 9.60659 9.2135 9.55325L7.14683 8.31992C6.6335 8.01325 6.2535 7.33992 6.2535 6.74659V4.01325C6.2535 3.73992 6.48016 3.51325 6.7535 3.51325C7.02683 3.51325 7.2535 3.73992 7.2535 4.01325V6.74659C7.2535 6.98659 7.4535 7.33992 7.66016 7.45992L9.72683 8.69325C9.96683 8.83325 10.0468 9.13992 9.90016 9.37992Z"
                    fill="#D0D5DD"
                  />
                </svg>
                <p className="text-[12px] md:text-[14px] ">
                  {formatTime(meetingInfo?.from)}-{formatTime(meetingInfo?.to)}
                </p>
              </div>
            </div>
            <div className="flex items-center w-full justify-between text-[14px]">
              <div className="flex">
                {meetingInfo?.attendees?.slice(0, 3).map((image, index) => (
                  <img
                    key={index}
                    className=" w-[25px] h-[25px] rounded-full"
                    src={image?.userImage}
                    alt=""
                  />
                ))}
                {meetingInfo?.attendees?.length > 3 && (
                  <p className="flex items-center text-[10px] justify-center text-white bg-[#1552b2] w-[25px] h-[25px] rounded-full ">
                    +{meetingInfo?.attendees?.length - 3}
                  </p>
                )}
              </div>

              <button
                onClick={(e) => {
                  e.stopPropagation();
                  if (meetingInfo.link) {
                    window.open(
                      meetingInfo.link,
                      "_blank",
                      "noopener,noreferrer"
                    );
                  }
                }}
                className={`px-2 py-1 text-[10px] flex justify-center items-center  rounded-full p-1 bg-[#1552b2] text-white`}
              >
                Join Meet
              </button>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default MeetingCard;
