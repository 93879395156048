import React from "react";

const arTranslations = {
  "welcome": "أهلا بك",
  "workingHours": "ساعات العمل",
  "today": "اليوم",
  "clockIn": "تسجيل الحضور",
  "clockOut": "تسجيل الإنصراف",
  "location": "موقع",
  "inbox": "صندوق الوارد",
  "seemore": "شاهد المزيد",
  "inboxItems": {
    "item1": "وثيقة ذكية",
    "item2": "دقائق الاجتماع",
    "item3": "اجتماع",
    "item4": "مهامي",
    "item5": "الخدمات الذكية",
  },
  "mostusedservice": "لخدمات الأكثر استخداما",
  "mostusedItem": {
   "item1": "الخدمات الذكية", 
    "item2": "تقويم الاجتماع",
    "item3": "المهام"
  },
  "knowledgehub": "مركز المعرفة",
  "knowledgeItem": {
    "item1": "دليل الموظف",
    "item2": "مجلد مشترك",
    "item3": "روابط وفيديوهات",
    "item4": "تقريري",
    "item5": "أصولي",
    "item6": "مجموعات"
  },
  "allmytasks": "كل مهامي",
  "notask": "لا توجد مهام",
  "mytaskcard": "بطاقة المهام الخاصة بي",
  "taskname": "اسم المهمة",
  "projectname": "اسم المشروع",
  "assignedBy": "تم التعيين من قبل",
  "acceptedby": "مقبولة من قبل",
  "duedate": "تاريخ الاستحقاق",
  "meetingforToday": "اجتماع لهذا اليوم",
  "mymeeting": "اجتماعي",
  "meetingName": "اسم الاجتماع",
  "meetingdatetime": "تاريخ ووقت الاجتماع",
  "meetingloc": "موقع الاجتماع",
  "meetingorg": "منظم الاجتماع",
  "momcreator": "موم الخالق",
  "assignedTask": "المهام المعينة",
  "assignto": "يسند إلى",
  "assignby": "أسند بواسطة",
  "empOverview": "نظرة عامة على الموظفين",
  "priority": "أولوية",
  "completed": "المكتملة",
  "inprogress": "في تَقَدم ",
  "confirmurPart": "تأكيد مشاركتك",
  "yes": "نعم",
  "no": "لا",
  "mayBe": "ربما",
  "attendees": "الحاضرين",
  "notesTaker": "مدون الملاحظات",


  "contentItems": {
    "item0": "لوحة القيادة",
    "item1": "الحضور الذكي",
    "item2": "الخدمات الذكية",
    "item3": "طلب",
    "item4": "تقويم الاجتماع",
    "item5": "المشروعات",
    "item6": "المهام",
    "item7": "وثيقة ذكية",
    "item8": "دقائق الاجتماع",
    "item9": "تقرير الرواتب",
    "item10": "تقريري",
    "item11": "مجلد مشترك",
    "item12": "روابط وفيديوهات",
    "item13": "دليل الموظف",
    "item14": "مجموعات",
    "item15": "أصولي",
    "item16": "ملاحظة منظمة العفو الدولية",
    "item17": "محرك المستندات بالذكاء الاصطناعي",
    "item18": "بوت",
    "item19": "لتعرف الضوئي على الحروف والمسح الضوئي",
    "item20": "محادثة",
    "item21": "دعم تكنولوجيا المعلومات الذكية",
    "item24" : "شريط الإعلان",
    "item25" : "مولد قوات الدفاع الشعبي",
    "contentItems": {
      "dashboard": "لوحة تحكم الرئيس التنفيذي"
    },
  
  },

 
  "ceoInboxItem": {
    "item1": "دقائق الاجتماع",
    "item2": "وثيقة ذكية",
    "item3": "الخدمات الذكية",
  },

  "hrproduct": "الموارد البشرية والإنتاجية",
  "advanceai": "الذكاء الاصطناعي المتقدم",
  "newDoc": "مستند جديد",

  "titless": {
    "inbox": "صندوق الوارد",
    "sentItems": "العناصر المرسلة",
    "archive": "الأرشيف",
    "allDocuments": "جميع المستندات",
    "viewMore": "عرض المزيد",
    "draft": "مسودة",
    "cc" : "نسخة إلى",
    // request
    "Approved": "موافق عليه",
    "allrequest": "كل طلب",
    "pending":"معلق",
    "rejected": "مرفوض",
    "allmom": "كل أمي",
  },
// task 
  "titles": {
    "allTask": "جميع المهام",
    "inProgressTask": "المهام قيد التنفيذ",
    "completedTask": "المهام المكتملة",
    "pendingTask": "المهام المعلقة",
    "overAllTask": "المهام الإجمالية",
    "justin": "فقط في",
  },

  "task": "مهامي",
  "alltask": "جميع المهام",
  "newtasks": "مهمة جديدة",
  "overalltask": "المهمة الشاملة",
  "createTask": "إنشاء مهمة",
  "viewMore": "عرض المزيد",

  // Project
  "titlesss": {
    "allProject": "جميع المشاريع",
    "inProgress": "قيد التنفيذ",
    "completed": "المكتملة",
    "pending": "معلق",
    "hold": "في الانتظار"
  },

  // itsupport
  "titlessss": {
    "openTickets": "التذاكر المفتوحة",
    "inProgress": "قيد التنفيذ",
    "closed": "مغلق",
    "allTickets": "جميع التذاكر",
  },

  "titlesssss": {
    "attendance": "حضور",
    "meeting": "اجتماعات",
    "task": "المهام",
    "request": "طلب",
    "document": "وثائق",
  },

  // {Payroll}
  "translate" :{
    "payroll": "الراتب",
    "payrollDashboard": "لوحة الرواتب",
    "payrollDetails" : "تفاصيل الرواتب",
    "choosepayrollPeriod":"اختر فترة الرواتب",
    "PayrollReport" : "تقرير الرواتب",
    "payrollsummary":"ملخص الرواتب",
    "chooseperiod" : " اختر الفترة",
    "choosepayrollperiod" : "اختر فترة الرواتب"

  },

  "smartSupport": "دعم تكنولوجيا المعلومات الذكية",
  "projects": "المشروعات",
  "myprojectcard": "بطاقة مشروعي",
  "projectname": "اسم المشروع",
  "description": "وصف",
  "projmanager": "مدير المشروع",
  "teammembers": "أعضاء الفريق",
  "startdate": "تاريخ البدء",
  "enddate": "انتهاء العرض",
  "duration": "مدة",
  "noproject": "لا يوجد مشاريع!!",
  "createproj": "إنشاء مشروع",
  "employeeId": "الرقم الشخصي للموظف",
  "dateofjoin": "تاريخ الانضمام",
  "qualification": "مؤهل",
  "supervisor": "مشرف",
  "annuallevbal": "رصيد الإجازة السنوية",
  "annualsickbal": "رصيد الإجازات المرضية السنوية",
  "theme": "السمة",
  "changePass": "تغيير كلمة المرور",
  "logOut": "تسجيل خروج",
  "public": " عام",
  "private": "خاص",
  "newproject": "مشروع جديد",
  "shareFolder": "مجلد مشترك",
  "newfolder": "ملف جديد",
  "noMeetings": "لا توجد اجتماعات",
  "meeting": "الاجتماعات",
  "smartDocument": "وثيقة ذكية",
  "goBack": "الرجوع للخلف",
  "back": "للخلف",
  "minutesofMeeting": "دقائق الاجتماع",
  "newMom": "أمي جديدة",
  "createmeet": "إنشاء اجتماع",
  "attendanceRep": "تقرير الحضور",
  "smartService": "الخدمات الذكية",
  "pending": "قيد الانتظار",
  "forapproval": "للموافقة",
  "approved": "موافق عليه",
  "rejected": "مرفوض",
  "reject": "يرفض",
  "request": "طلب",
  "newrequest": "طلب جديد",
  "requestLeave": "طلب-إجازة",
  "requestSickleave": "طلب-إجازة مرضية",
  "requestworkfromRemote": "طلب العمل عن بعد",
  "others": "آخر..",
  "casual": "غير رسمي",
  "others": "آخر..",
  "casual": "غير رسمي",
  "fullday": "يوم كامل",
  "shortLeave": "إجازة قصيرة",
  "sick": "مريض",
  "leaverequest": "طلب الإجازة",
  "close": "يغلق",
  "requestSubmitSucces": "تم تقديم الطلب بنجاح!",
  "selectcategory": "حدد الفئة",
  "docSelected": "تم تحديد المستند",
  "*documentisMandatory": "* الوثيقة إلزامية للإجازة المرضية أكثر من يومين",
  "reason": "سبب",
  "approver": "موافق",
  "starttime": "وقت البدء",
  "endtime": "وقت النهاية",
  "newservice": "خدمة جديدة",
  "createmom": "خلق أمي",
  "edit":"يحرر",
  "date": "التاريخ",
  "inprogressProj": "مشروع قيد التقدم",
  "cancelmeet": "إلغاء الاجتماع",
  "nodocument": "لا توجد مستندات",
  "document": "وثيقة",
  "reviewed": "تمت مراجعته",
  "view": "منظر",
  "docHistory": "تاريخ الوثيقة",
  "relatedAttach": "المرفقات ذات الصلة",
  "docComment":"تعليقات الوثيقة",
  "shareFolderView": "عرض المجلد المشترك",
  "upload": "رفع",
  "linksandvideos": "الروابط ومقاطع الفيديو",
  "newlinkvideo": "رابط جديد وفيديو",
  "empDirectory": "دليل الموظفين",
  "groupteam": "المجموعة / الفريق",
  "createGroup": "إنشاء مجموعة",
  "assets": "أصول",
  "createAsset": "إنشاء الأصول",
  "AInotes": "ملاحظات الذكاء الاصطناعي",
  "startSpeaking": "اضغط على الزر وابدأ في التحدث",
  "listening": "يستمع",
  "notListening": "لا يستمع",
  "smartitSupport": "دعم تكنولوجيا المعلومات الذكية",
  "allTask": "جميع المهام",
  "ticketDetails": "تفاصيل التذكرة",
  "openTickets": "التذاكر المفتوحة",
  "closed": "مغلق",
  "allTickets": "جميع التذاكر",
  "tickets": "التذاكر",
  "noTickets": "لا تذاكر",
  "createTickets": "إنشاء التذاكر",
  "createdBy": "تم الإنشاء بواسطة",
  "remark": "ملاحظة",
  "remarkCannotbeempty": "لا يمكن أن تكون الملاحظة فارغة",
  "newTicket": "تذكرة جديدة",
  "attachments": "المرفقات",
  "title": "العنوان",
  "browse": "تصفح",
  "cancel": "إلغاء",
  "submit": "إرسال",
  "addTickets": "إضافة تذاكر",
  "noFiles": "لا توجد ملفات",
  "newMeeting": "اجتماع جديد",
  "newemployee": "موظف جديد",
  "ocrscan": "الماسح الضوئي للتعرف الضوئي على الحروف",
  "camera": "آلة تصوير",
  "gallery": "معرض",
  "capture": "يأسر",
  "subject": "الموضوع",
  "agenda": "جدول الأعمال",
  "repeat": "التكرار",
  "everyMonday": "كل يوم إثنين",
  "everyTuesday": "كل يوم ثلاثاء",
  "everyWednesday": "كل يوم أربعاء",
  "everyThursday": "كل يوم خميس",
  "everyFriday": "كل يوم جمعة",
  "attendees": "الحضور",
  "noteTaker": "مدون ملاحظات",
  "link": "وصلة",
  "time": "الوقت",
  "from": "من",
  "to": "إلى",
  "cancel": "إلغاء",
  "send": "إرسال",
  "Position": "موضع",

  "actionPlan": "خطة عمل",
  "linkedproject": "مشروع مرتبط",
  "high": "عالي",
  "medium": "متوسط",
  "low": "قليل",
  "assignTask": "تعيين المهمة",
  "workFlow": "تدفق العمل",
  "projtitle": "عنوان المشروع",
  "clientname": "اسم العميل",
  "techStack": "المكدس الفني",
  "create": "يخلق",
  "nameTeamMembers": "اسم أعضاء الفريق",
  "nameProjManager": "إسم مدير المشروع",
  "id": "بطاقة تعريف",
  "reviewedby": "تمت مراجعته بواسطة",
  "signby": "وقع من طرف",
  "employee": "موظف",
  "team": "فريق",
  "member": "عضو",
  "cc": "نسخة",
  "save": "حفظ",
  "editmeeting": "تحرير الاجتماع",
  "selectedEmployees": "الموظفين المختارين",
  "selectEmployees": "حدد الموظفين",
  "assignEmployees": "تعيين الموظفين",
  "employees": "الموظفين",
  "noactionreq": "👍لا يلزم اتخاذ أي إجراء ",
  "updateMeeting": "اجتماع التحديث",
  "UpdateProject": "تحديث المشروع",
  "progress": "تقدم",
  "inProgress": "قيد التنفيذ",
  "completed": "المكتملة",
  "pending": "معلق",
  "hold": "في الانتظار",
  "update": "تحديث",
  "editTask": "تحرير المهمة",
  "through": " من خلال",
  "forInfo": "للحصول على معلومات",
  "forAction": "للعمل",
  "forApprove": "للموافقة",
  "preview": "معاينة",
  "newSharedFolder": "مجلد مشترك جديد",
  "whocanacess": "من يستطيع الوصول",
  "newLink&Video": "رابط وفيديو جديد",
  "newTeam": "فريق جديد",
  "groupName": "اسم المجموعة",
  "newAssets": "الأصول الجديدة",
  "serialno": "الرقم التسلسلي",
  "productname": "اسم المنتج",
  "productvalue": "قيمة المنتج",
  "smartAttendance": "الحضور الذكي",
  "viewallemp": "عرض جميع الموظفين",
  "report": "تقرير",
  "attendancereport": "تقرير الحضور",
  "norequest": "لا طلبات",
  "initiated": "بدأ",
  "users": "المستخدمين",
  "payroll": "كشوف المرتبات",
  "payrollReport": "تقرير الرواتب",
  "selectMonth": "اختر الشهر",
  "select": "يختار",
  "generate": "يولد",
  "selectdate": "حدد تاريخا",
  "selecttime": "حدد الوقت",
  "remote": "بعيد",
  "office": "مكتب",
  "forgetClockin&out": "ننسى على مدار الساعة في وساعة الخروج",
  "clockOutConfirm": "تأكيد انتهاء الساعة",
  "clockInConfirm": "الساعة في التأكيد",
  "AreyouwanttoClockOut": "هل أنت متأكد أنك تريد إيقاف الساعة؟",
  "AreyouwanttoClockIn": "هل أنت متأكد أنك تريد تسجيل الدخول؟",
  "forgotclckin&out": "نسيت الساعة داخل / خارج",
  "timezone": "تغيير المنطقة الزمنية",
  "newchat": "دردشة جديدة",
  "selectTimeZone": "حدد المنطقة الزمنية",
  "home": "بيت",
  "service": "خدمة",
  "BOT": "بوت",
  "chat": "محادثة",
  "notify": "إشعار",
  "saveforlater": "حفظ لوقت لاحق",
  "Swipetoclockout": "اسحب للخروج",
  "Swipetoclockin": "اسحب لتسجيل الدخول",
  "accepted": "مقبول",
  "maybe": "ربما",
  "meetingTitle": "عنوان الاجتماع",
  "payrollSummary": "ملخص الرواتب",
  "Tasks": "المهام",
  "Meetings": "الاجتماعات",
  "Smart Document": "المستند الذكي",
  "projecttask": "مهمة المشروع",
  "status": "حالة",
  "remainingdays": "الأيام المتبقية",
  "speech": "خطاب",
  "history": "تاريخ",
  "reset": "إعادة ضبط",
  "aidocumentengine": "محرك المستندات بالذكاء الاصطناعي",
  "chathistory": "تاريخ الدردشة",

};


export default arTranslations