import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "../components/Theme/Themecontext";
import { Link } from "react-router-dom";
import { ReactComponent as Group } from "../images/SvgCollection/Group.svg";
import CircularProgress from "../components/CircularProgress";
import Loader from "../common/Loader";

function ProjectCard({ project, styleKey, isLoading,truncate }) {
  const { t, i18n } = useTranslation();
  const { styles } = useTheme();
  const { blacktext } = styles;

  const dynamicStyle = styles[styleKey];

  const formatTime = (timeString) => {
    if (!timeString) return "00:00";
    const time = new Date(timeString);
    let hours = time.getHours();
    let minutes = time.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    const formattedTime = hours + ":" + minutes + " " + ampm;
    return formattedTime;
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen w-full">
        <Loader />
      </div>
    );
  }
  const truncateText = (text, wordCount) => {
    if (!text) {
      return "";
    }

    const words = text.split(" ");
    if (words.length <= wordCount) return text;
    return words.slice(0, wordCount).join(" ") + "...";
  };

  const projectArray = Array.isArray(project) ? project : [project];

  return (
    <div className="">
      {projectArray.length > 0 && projectArray[0] ? (
        <div
          className={` ${
            i18n.language === "ar" ? "lg:justify-end" : "lg:justify-start"
          }`}
        >
          {projectArray.map((project) => (
            <div
              key={project?.projectId}
              style={dynamicStyle}
              className="min-w-[285px] w-full rounded-xl p-2 "
            >
              <div className="flex-cols items-center">
                <div className="flex justify-between text-[14px] mb-3">
                  <div className="flex-cols w-[65%] items-center">
                    <h1 style={blacktext} className="font-semibold">
                      {project?.projectName}
                    </h1>
                    <div className="flex items-center gap-2">
                      {/* {project.techStack &&
                        project.techStack.map((tech, index) => (
                          <p key={index} className="text-[12px]">
                            {tech}
                          </p>
                        ))} */}
                        <p className="text-[12px]">{
                          truncate === true ? 
                        truncateText(project?.projectDes,4) : project?.projectDes}</p>
                    </div>
                    <div>
                      <div className="flex justify-between items-start mt-2">
                        <div className="flex">
                          <div className="self-end flex lg:gap-1 gap-5 xl:gap-3">
                            <div className="flex-cols">
                              <h1 className="mb-3 text-[12px] md:text-[14px]">
                                Team Members
                              </h1>
                              <div className="flex">
                                {project.teamMember &&
                                  project.teamMember
                                    .slice(0, 2)
                                    .map((member) => (
                                      <img
                                        key={member.userId}
                                        className="w-[20px] h-[20px] md:w-[30px] md:h-[30px] rounded-full"
                                        src={member?.userImage}
                                        alt=""
                                      />
                                    ))}
                                {project.teamMember &&
                                  project.teamMember.length > 2 && (
                                    <p className="flex items-center text-[10px] md:text-[12px] justify-center text-white bg-[#1552b2] w-[20px] h-[20px] md:w-[30px] md:h-[30px] rounded-full ">
                                      +{project.teamMember.length - 2}
                                    </p>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex-cols">
                          <h1 className="mb-3 text-[12px] md:text-[14px]">
                            Due Date
                          </h1>
                          <p className="text-[12px] md:text-[14px]">
                            {formatTime(project.projectEndDate)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-end w-[35%] place-items-start gap-1">
                    <CircularProgress progress={project.projectProgress} />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className=" flex-col items-center">
          <Group />
          <p
            style={blacktext}
            className="text-[14px] xl:text-[16px] text-center "
          >
            {t("noactionreq")}
          </p>
          <p className="text-center text-[12px] xl:text-[14px]">
            {" "}
            It's all clear for now. Add something new to fill this area{" "}
          </p>
        </div>
      )}
    </div>
  );
}

export default ProjectCard;
