import React, { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { lazy } from "react";

// Define lazy-loaded components
const Ocr = lazy(() => import("../pages/Ocr"));

const Dashboard = lazy(() => import("../pages/Dashboard/Dashboard"));
const EmployeeDirectory = lazy(() =>
  import("../pages/EmployeeDirectory/EmployeeDirectory")
);
const CreateEmployee = lazy(() =>
  import("../pages/CreateEmployee/CreateEmployee")
);
const MeetingCalendar = lazy(() =>
  import("../pages/MeetingCalendarAll/MeetingCalendarAll")
);
const CreateMeetingCalendar = lazy(() =>
  import("../pages/CreateMeetingCalendar/CreateMeetingCalendar")
);
const MeetingDetails = lazy(() =>
  import("../pages/MeetingDetails/MeetingDetails")
);
const UserProfile = lazy(() => import("../pages/UserProfile/UserProfile"));
const TaskDashboard = lazy(() =>
  import("../pages/TaskDashboard/TaskDashboard")
);
const CreateTask = lazy(() => import("../pages/CreateTask/CreateTask"));
const TaskDetail = lazy(() => import("../pages/TaskDetail/TaskDetail"));
const MyTask = lazy(() => import("../pages/MyTask/MyTask"));
const ViewSmartAttendance = lazy(() =>
  import("../pages/ViewSmartAttendance/ViewSmartAttendance")
);
const SharedFolder = lazy(() => import("../pages/SharedFolder/SharedFolder"));
const NewSharedFolder = lazy(() =>
  import("../pages/NewSharedFolder/NewSharedFolder")
);
const SharePublicView = lazy(() =>
  import("../pages/SharePublicView/SharePublicView")
);
const SharePrivateView = lazy(() =>
  import("../pages/SharePrivateView/SharePrivateView")
);
const SmartDocument = lazy(() => import("../pages/SmartDocument"));
const NewLinksAndVides = lazy(() =>
  import("../pages/NewLinksAndVideos/NewLinksAndVideos")
);
const LinksAndVideos = lazy(() =>
  import("../pages/LinksAndVideos/LinksAndVideos")
);
const NewDocument = lazy(() => import("../pages/NewDocument"));
const ViewDocument = lazy(() => import("../pages/ViewDocument"));
const SignDocument = lazy(() => import("../pages/SignDocument"));
const ReviewedDocument = lazy(() => import("../pages/ReviewedDoc"));
const MOM = lazy(() => import("../pages/MOM"));
const Request = lazy(() => import("../pages/Request"));
const ViewRequest = lazy(() => import("../pages/ViewRequest"));
const ForgotClockinOut = lazy(() =>
  import("../pages/ForgotClockin&out/index.js")
);
const ViewReqbyId = lazy(() => import("../pages/ViewReqbyID"));
const TimeZoneChange = lazy(() => import("../pages/TimeZone/index.js"));

const AddMom = lazy(() => import("../pages/AddMom"));
const CreateMom = lazy(() => import("../pages/CreateMom"));
const ViewMom = lazy(() => import("../pages/ViewMom"));
const WorkFlow = lazy(() => import("../pages/CreateWorkFlow"));
const AINote = lazy(() => import("../pages/AINote/AINote"));
const QuickAll = lazy(() => import("../components/QuickAll"));
const Assets = lazy(() => import("../pages/Assets/Assets"));
const viewassets = lazy(()=>import("../pages/ViewAssets"))
const NewAsset = lazy(() => import("../pages/NewAsset/NewAsset"));
const Group = lazy(() => import("../pages/Group/Group"));
const ViewGroup = lazy(() => import("../pages/ViewGroup/ViewGroup"));
const ViewTeam = lazy(() => import("../pages/ViewTeam/index.js"));
const EditTeam = lazy(() =>import("../pages/EditTeam"))
const NewGroup = lazy(() => import("../pages/NewGroup/NewGroup"));
const SmartServiceDashboard = lazy(() =>
  import("../pages/SmartServiceDashboard/SmartServiceDashboard.js")
);
const LeaveRequest = lazy(() => import("../pages/LeaveRequest/LeaveRequest"));
const SickLeave = lazy(() => import("../pages/SickLeave/SickLeave"));
const WorkHomeRequest = lazy(() =>
  import("../pages/WorkHomeRequest/WorkHomeRequest")
);
const ViewSmartService = lazy(() =>
  import("../pages/ViewSmartService/ViewSmartService")
);
const ProjectDashboard = lazy(() =>
  import("../pages/ProjectDashboard/ProjectDashboard")
);
const CreateProject = lazy(() =>
  import("../pages/CreateProject/CreateProject")
);
const AllProject = lazy(() => import("../pages/AllProject/AllProject"));
const ViewProject = lazy(() => import("../pages/ViewProject/ViewProject"));
const AllProjectTask = lazy(() =>
  import("../pages/AllProjectTask/AllProjectTask")
);
const CeoDashboard = lazy(() => import("../pages/CeoDashboard/CeoDashboard"));
const OverAllInProgressTask = lazy(() =>
  import("../pages/OverAllInProgressTask/OverAllInProgressTask")
);
const OverAllInProgressProject = lazy(() =>
  import("../pages/OverAllInProgressProject/OverAllInProgressProject")
);
const CeoUserlist = lazy(() => import("../pages/CeoUserlist/CeoUserlist"));
const CeoEmployees = lazy(() => import("../pages/CeoEmployees/CeoEmployes"));
const AiDocumentEngine = lazy(() =>
  import("../pages/AiDocumentEngine/AiDocumentEngine")
);
const PdfDocument = lazy(() => import("../pages/PdfDocument"));
const PdfMom = lazy(() => import("../pages/PdfMom"));
const SignMom = lazy(() => import("../pages/SignMom"));
const ReviewMom = lazy(() => import("../pages/ReviewMom"));
const ViewpdfMom = lazy(() => import("../pages/ViewpdfMom/ViewpdfMom"));
const ViewPdf = lazy(() => import("../pages/ViewPdf/ViewPdf"));
const ITSupportManager = lazy(() =>
  import("../pages/ITSupportManager/ITsupport")
);
const ItSupportView = lazy(() =>
  import("../pages/ItSupportView/ItSupportView")
);

const AssignItTickets = lazy(() =>
  import("../pages/AssignItTickets/AssignItTickets")
);
const ViewTickets = lazy(() => import("../pages/ViewTickets/index"));
const ITsupportemployee = lazy(() => import("../pages/ITsupportemployee"));
const AddTickets_E = lazy(() => import("../pages/AddTickets"));
const CeoAttendance = lazy(() => import("../pages/CeoAttendance"));
const CeoRequest = lazy(() => import("../pages/CeoRequest"));
// const CeoViewsmart = lazy(()=>import("../pages/viewceosmartservice"))
const CeoMeetings = lazy(() => import("../pages/CeoMeetings"));
const CeoTasks = lazy(() => import("../pages/CeoTask"));
const ViewCeoTasks = lazy(() => import("../pages/ViewCeoTask"));
const CeoDocuments = lazy(() => import("../pages/CeoDocuments"));
const AttendanceReport = lazy(() => import("../pages/AttendanceReport"));
const EditMeeting = lazy(() => import("../pages/EditMeeting"));
const EditProjects = lazy(() => import("../pages/EditProjects"));
const EditTask = lazy(() => import("../pages/EditTask"));
const PayrollDashboard = lazy(() => import("../pages/PayrollDashboard"));
const PayrollDetails = lazy(() => import("../pages/PayrollDetails"));
const userprofileDetails = lazy(()=> import("../pages/UserProfileDetails"))

const Notification = lazy(() =>
  import("../pages/Notification/Notification.js")
);

const ChangePassword = lazy(() => import("../pages/Changepassword/index.js"));

const ChatBot = lazy(() => import("../pages/ChatBot/index.js"));
const Chat = lazy(() => import("../pages/Chat/index.js"));
const NewChat = lazy(() => import("../pages/NewChat/index.js"));
const ChatPage = lazy(() => import("../pages/ChatPage/index.js"));
const Bot = lazy(() => import("../pages/Bot"));
const BotHistory = lazy(() => import("../pages/BotHistory"));
const ProjectMeeting = lazy(() => import("../pages/ProjectMeeting/ProjectMeeting.js"));
const ProjectTask = lazy(() => import("../pages/ProjectTask/ProjectTask.js"));
const ProjectSmartDocument = lazy(() => import("../pages/ProjectSmartDocument/ProjectSmartDocument.js"));
const Holiday = lazy(() => import("../pages/Holiday/Holiday.js"));
const AddHoliday = lazy(() => import("../pages/AddHoliday/AddHoliday.js"));
const ViewHoliday = lazy(() => import("../pages/ViewHoliday/ViewHoliday.js"));
const Announcement = lazy(() => import("../pages/Announcement"));
const announcementdetail = lazy(() => import("../pages/AnnouncementDetails"));
const AddAnnouncement = lazy(() => import("../pages/AddAnnouncement"));
const EditEmployees = lazy(() => import("../pages/EditEmployees"));
const EditLeaveRequest = lazy(() => import("../pages/EditLeaveRequest"));
const EmployeeDetails = lazy(()=> import("../pages/EmployeeDetails/index.js"))

const AiPdfGenerator  = lazy(() => import("../pages/AiPdfGenerator/AiPdfGenerator.js"));



// Define core routes
const coreRoutes = [
  {
    path: "/home",
    component: Dashboard,
  },
  {
    path : "/home/announcement",
    name: "Announcement",
    component: Announcement,

  },
  {
    path: "/home/announcement/announcementdetail",
    name: "announcement detail",
    component: announcementdetail,
  },
  {
    path : "/home/announcement/addannouncement",
    name: "Add Announcement",
    component: AddAnnouncement,
  },
  {
    path: "/home/userList",
    name: "Employee Directory",
    component: EmployeeDirectory,
  },
  {
    path : "/home/editemployee",
    name: "Edit Employee",
    component: EditEmployees,
  },
  {
    path : "/home/employeedetails",
    name: "Employee Details",
    component: EmployeeDetails,
  },
  {
    path: "/home/meeting",
    name: "Meeting Calendar",
    component: MeetingCalendar,
  },
  {
    path: "/home/meeting/createmeeting",
    component: CreateMeetingCalendar,
    name: "New Meeting",
  },
  {
    path: "/home/meeting/details",
    component: MeetingDetails,
    name: "Meeting Details",
  },
  {
    path: "/home/meeting/details/editmeeting",
    component: EditMeeting,
    name: "Edit Meeting",
  },
  {
    path: "/home/userprofile",
    component: UserProfile,
    name: "Profile",
  },
  {
    path: "/home/userprofile/userprofiledetails",
    component: userprofileDetails,
    name: "userprofileDetails",
  },
  {
    path: "/home/userprofile/changepassword",
    component: ChangePassword,
    name: "change Password",
  },
  {
    path: "/home/task",
    component: TaskDashboard,
    name: "Task",
  },
  {
    path: "/home/task/createtask",
    component: CreateTask,
    name: "New Task",
  },
  {
    path: "/home/task/edittask",
    component: EditTask,
    name: "Edit Task",
  },
  {
    path: "/home/task/taskdetail",
    component: TaskDetail,
    name: "Task Detail",
  },
  {
    path: "/home/task/mytask",
    component: MyTask,
    name: "My Task",
  },
  {
    path: "/home/smartattendance",
    component: ViewSmartAttendance,
    name: "Attendance",
  },
  {
    path: "/home/sharedfolder",
    component: SharedFolder,
    name: "Shared Folder",
  },
  {
    path: "/home/sharedfolder/newsharedfolder",
    component: NewSharedFolder,
    name: "New Folder",
  },
  {
    path: "/home/sharedfolder/public",
    component: SharePublicView,
    name: "Public",
  },
  {
    path: "/home/sharedfolder/private",
    component: SharePrivateView,
    name: "Private",
  },
  {
    path: "/home/smartdocument",
    component: SmartDocument,
    name: "Document",
  },
  {
    path: "/home/linksandvideos",
    component: LinksAndVideos,
    name: "Link & Video",
  },

  {
    path: "/home/smartdocument/newdocument",
    component: NewDocument,
    name: "New Document",
  },
  {
    path: "/home/smartdocument/viewdocument",
    component: ViewDocument,
    name: "Document",
  },
  {
    path: "/home/smartdocument/signdocument",
    component: SignDocument,
    name: "Document",
  },
  {
    path: "/home/smartdocument/reviewdocument",
    component: ReviewedDocument,
    name: "Document",
  },
  {
    path: "/home/mom",
    component: MOM,
    name: "Document",
  },
  {
    path: "/home/add_mom",
    component: AddMom,
    name: "Mom",
  },
  {
    path: "/home/add_mom/create_mom",
    component: CreateMom,
    name: "Mom",
  },
  {
    path: "/home/create_mom/work_flow",
    component: WorkFlow,
    name: "Mom",
  },

  {
    path: "/home/viewmom",
    component: ViewMom,
    name: "Mom",
  },
  {
    path: "/home/viewmom/pdfmom",
    component: PdfMom,
    name: "pdf",
  },
  {
    path: "/home/viewmom/signmom",
    component: SignMom,
    name: "WorkFlow",
  },
  {
    path: "/home/viewmom/reviewmom",
    component: ReviewMom,
    name: "ReviewMom",
  },
  {
    path: "/home/ainote",
    component: AINote,
    name: "AI Notes",
  },
  {
    path: "/home/quickall",
    component: QuickAll,
    name: "Services",
  },
  {
    path: "/home/assets",
    component: Assets,
    name: "Assets",
  },
  {
    path:"/home/assets/viewassets",
    component : viewassets,
    name: "View Assets",
  },
  {
    path: "/home/assets/newasset",
    component: NewAsset,
    name: "New Assets",
  },
  {
    path: "/home/group",
    component: Group,
    name: "Group",
  },
  {
    path: "/home/group/viewgroup",
    component: ViewGroup,
    name: "Group Info",
  },
  {
    path: "/home/group/viewgroup/viewteam",
    component: ViewTeam,
    name: "View Team",
  },
  {
    path : "/home/group/viewgroup/editteam",
    component: EditTeam,
    name: "Edit Team",
  },
  {
    path: "/home/group/newgroup",
    component: NewGroup,
    name: "New Team",
  },
  {
    path: "/home/smartservicedashboard",
    component: SmartServiceDashboard,
    name: "Smart Service",
  },
  {
    path: "/home/smartservicedashboard/leaverequest",
    component: LeaveRequest,
    name: "LeaveRequest",
  },
  {
    path: "/home/smartservicedashboard/workhomerequest",
    component: WorkHomeRequest,
    name: "WFH Request",
  },
  {
    path: "/home/smartservicedashboard/viewsmartservice/edit",
    component: EditLeaveRequest,
    name: "LeaveHistory",
  },
  {
    path: "/home/smartservicedashboard/Sickleave",
    component: SickLeave,
    name: "SickLeave",
  },
  {
    path: "/home/smartservicedashboard/viewsmartservice",
    component: ViewSmartService,
    name: "Smart Service",
  },
  {
    path: "/home/projectdashboard",
    component: ProjectDashboard,
    name: "Projects",
  },

  {
    path: "/home/projectdashboard/allproject",
    component: AllProject,
    name: "Project",
  },
  {
    path: "/home/projectdashboard/allproject/viewproject",
    component: ViewProject,
    name: "projects",
  },
  {
    path: "/home/projectdashboard/allproject/viewproject/meetings",
    component: ProjectMeeting,
    name: "Meetings",
  },
  {
    path: "/home/projectdashboard/allproject/viewproject/tasks",
    component: ProjectTask,
    name: "Tasks",
  },
  {
    path: "/home/projectdashboard/allproject/viewproject/smartdocument",
    component: ProjectSmartDocument,
    name: "Document",
  },
  {
    path: "/home/projectdashboard/allproject/viewproject/allprojecttask",
    component: AllProjectTask,
    name: "Task",
  },
  {
    path: "/home/overallinprogresstask",
    component: OverAllInProgressTask,
    name: "Over All In Progress Task",
  },
  {
    path: "/home/overallinprogressproject",
    component: OverAllInProgressProject,
    name: "Over All In Progress Project",
  },

  {
    path: "/home/smartdocument/pdfdocument",
    component: PdfDocument,
    name: "Document",
  },

  {
    path: "/home/ceodashboard/ceouserlist",
    component: CeoUserlist,
    name: "Users",
  },
  {
    path: "/home/ceodashboard/ceoemployees",
    component: CeoEmployees,
    name: "Employees",
  },
  {
    path: "/home/aidocumentengine",
    component: AiDocumentEngine,
    name: "AI Document Engine",
  },
  {
    path: "/home/ceodashboard",
    component: CeoDashboard,
    name: "CEO Dashboard",
  },

  {
    path: "/home/smartdocument/reviewdocument/viewpdf",
    component: ViewPdf,
    name: "pdf",
  },
  {
    path: "/home/viewmom/reviewmom/viewpdfmom",
    component: ViewpdfMom,
    name: "pdf",
  },

  {
    path: "/home/userList/add",
    name: "Adduser",
    component: CreateEmployee,
  },
  {
    path: "/home/newlinksandvideos",
    component: NewLinksAndVides,
    name: "New Link & Video",
  },

  {
    path: "/home/itsupportmanager",
    component: ITSupportManager,
    name: "Tickets-details",
  },
  {
    path: "/home/itsupportmanager/viewtickets",
    component: ViewTickets,
    name: "Tickets",
  },
  {
    path: "/home/itsupportmanager/addtickets",
    component: AddTickets_E,
    name: "addTickets",
  },
  {
    path: "/home/itsupportemployee",
    component: ITsupportemployee,
    name: "Tickets",
  },
  {
    path: "/home/itsupportmanager/viewtickets/ViewbyId",
    component: ItSupportView,
    name: "Tickets View",
  },

  {
    path: "/home/itsupportmanager/viewtickets/ViewbyId/Assignticket",
    component: AssignItTickets,
    name: "Assign Ticket",
  },

  {
    path: "/home/itsupportemployee/addtickets",
    component: AddTickets_E,
    name: "addTickets",
  },

  {
    path: "/home/ceodashboard/ceoemployees/ceoattendance",
    component: CeoAttendance,
    name: "Attendance",
  },
  {
    path: "/home/ceodashboard/ceoemployees/ceorequest",
    component: CeoRequest,
    name: "request-details",
  },
  // {
  //   path: "/home/ceodashboard/ceoemployees/ceorequest/viewrequest",
  //   component: viewceosmartservice,
  //   name: "viewrequest"
  // },

  // ,,,,
  {
    path: "/home/ceodashboard/ceoemployees/ceomeetings",
    component: CeoMeetings,
    name: "meeting-details",
  },

  {
    path: "/home/ceodashboard/ceoemployees/ceotasks/viewceotasks",
    component: ViewCeoTasks,
    name: "viewTasks-details",
  },
  {
    path: "/home/ceodashboard/ceoemployees/ceodocuments",
    component: CeoDocuments,
    name: "documents",
  },
  {
    path: "/home/ceodashboard/attendancereport",
    component: AttendanceReport,
    name: "Attendance Report",
  },
  {
    path: "/home/projectdashboard/allproject/viewproject/editprojects",
    component: EditProjects,
    name: "edit-projects",
  },
  {
    path: "/home/notification",
    component: Notification,
    name: "Notification",
  },
  {
    path: "/home/itsupportmanager",
    component: ITSupportManager,
    name: "IT Support Manager",
  },
  {
    path: "/home/chatbot",
    component: ChatBot,
    name: "ChatBot",
  },
  {
    path: "/home/chat",
    component: Chat,
    name: "Chat",
  },
  {
    path: "/home/chat/newchat",
    component: NewChat,
    name: "All Users",
  },
  {
    path: "/home/chat/newchat/chatpage",
    component: ChatPage,
    name: "User",
  },

  {
    path: "/home/request",
    component: Request,
    name: "Request",
  },
  {
    path: "/home/viewrequest",
    component: ViewRequest,
    name: "View Request",
  },
  {
    path: "/home/viewrequest/viewbyId",
    component: ViewReqbyId,
    name: "View RequestById",
  },
  {
    path: "/home/forgotclockin&out",
    component: ForgotClockinOut,
    name: "Forgot Clock-in and Out",
  },
  {
    path: "/home/timezonechange",
    component: TimeZoneChange,
    name: "Time Zone Change",
  },
  {
    path: "/home/PayrollDashboard",
    component: PayrollDashboard,
    name: "Payroll Dashboard",
  },
  {
    path: "/home/PayrollDetails",
    component: PayrollDetails,
    name: "Payroll Details",
  },
  {
    path: "/home/Bot",
    component: Bot,
    name: "BOT",
  },
  {
    path: "/home/Bot/BotHistory",
    component: BotHistory,
    name: "Bot History",
  },
  {
    path: "/home/ocr",
    component: Ocr,
    name: "OCR Scanner",
  },
  {
    path: "/home/holiday",
    component: Holiday,
    name: "Holiday",
  },
  {
    path: "/home/holiday/addholiday",
    component: AddHoliday,
    name: "Add Holiday",
  },
  {
    path: "/home/viewholiday",
    component: ViewHoliday,
    name: "View Holiday",
  },
  {
    path: "/home/aipdfgenerator",
    component: AiPdfGenerator,
    name: "AI PDF Generator",
  }
];

const token = window.localStorage.getItem("token");

let userInfo = null;
if (token) {
  try {
    const decoded = jwtDecode(token);
    userInfo = decoded;
  } catch (error) {
    console.error("Token is invalid:", error);
    // window.location.href = '/login'; // Redirect to login page
  }
} else {
  console.error("Token is missing");
  // window.location.href = '/login'; // Redirect to login page
}

var routes = coreRoutes;
if (userInfo) {
  if (userInfo.role === 0 || userInfo.role === 1) {
    routes = [
      ...coreRoutes,
      {
        path: "/home/projectdashboard/createproject",
        component: CreateProject,
        name: "New Project",
      },
      {
        path: "/home/ceodashboard/ceoemployees/ceotasks",
        component: CeoTasks,
        name: "tasks-details",
      },
    ];
  }
}

export default routes;
