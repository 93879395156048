import React, { useEffect, useState } from "react";
import CardOne from "../../components/CardOne";
import { Link } from "react-router-dom";
import QuickCard from "../../components/QuickCard";
import { jwtDecode } from "jwt-decode";
import { useTheme } from "../../components/Theme/Themecontext";
import MeetingCard from "../../AtomicComponent/MeetingCard";
import { useTranslation } from "react-i18next";
import VoiceBot from "../../components/VoiceBot";
import { X } from "lucide-react";
import Lottie from "lottie-react";
import announcementAnimation from "../../images/FYCrXI7K14.json";
import ProjectCard from "../../AtomicComponent/ProjectCard";
import { ReactComponent as NoMeet } from "../../images/SvgCollection/Frame.svg"
import { ReactComponent as NoTask } from "../../images/SvgCollection/Frame2.svg"
import Loader from "../../common/Loader";


// import Videocall from "../../components/Webrtc";
function Dashboard() {
  const { t, i18n } = useTranslation();

  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;

  const token = localStorage.getItem("token");
  const userInfo = jwtDecode(token);

  const { styles } = useTheme();
  const { component, blacktext, bluetext, interior } = styles;
  const [reloadCounter, setReloadCounter] = useState(0);
  const [Announcement, setAnnouncement] = useState([]);
  const [taskInfo, setTaskInfo] = useState([]);
  const [announcePopup, setAnnouncePopup] = useState(false);
  const [currentAnnouncementIndex, setCurrentAnnouncementIndex] = useState(0);
  const [project, setProject] = useState([]);
  const [isRefresh, setIsRefresh] = useState(false);
  const [endTime,setEndTime] = useState(false)
  const [loading,setLoading] = useState(true)
  const [error,setError] = useState("")
  
  const RELOAD_INTERVAL = 500;

  const [meetingInfo, setMeetingInfo] = useState([]);

  const fetchUserclkInfo = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`${baseUrl}/home/todayMeeting`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();

        setMeetingInfo(data?.result);
        const now = new Date();
        const allFilteredMeetingsEnded = data?.result?.every((meeting) => {
          const meetingEndTime = new Date(meeting?.to);
          return now >= meetingEndTime;
        });

        setEndTime(allFilteredMeetingsEnded);
        setLoading(false)

        
      } else {
        throw new Error("Failed to fetch booking details");
      }
    } catch (error) {
      console.error("Error fetching booking details:", error);
      setLoading(false)
      setError('Error in fetching booking details:', error)
    }
  };

  const TimeFormat = (dateString) => {
    const today = new Date(dateString);
    const Hours = String(today.getHours()).padStart(2, "0");
    const Mins = String(today.getMinutes()).padStart(2, "0");
    return Hours + ":" + Mins;
  };

  const formatTime = (timeString) => {
    if (!timeString) return "00:00";
    const time = new Date(timeString);
    let hours = time.getHours();
    let minutes = time.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    const formattedTime = hours + ":" + minutes + " " + ampm;
    return formattedTime;
  };

  const onClose = () => {
    setAnnouncePopup(false);
  };

  useEffect(() => {
    fetchUserclkInfo();
    const intervalId = setInterval(() => {
      setReloadCounter((prevCounter) => prevCounter + 1);
    }, RELOAD_INTERVAL);

    return () => clearInterval(intervalId);
  }, [isRefresh]);

  setTimeout(() => {
    setIsRefresh(false);
  }, 1000);

  const handleRefresh = () => {
    setIsRefresh(true);
  };

  const fetchUserTask = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`${baseUrl}/home/todayTask`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        setTaskInfo(data?.result);
        setLoading(false)
      } else {
        throw new Error("Failed to fetch booking details");
      }
    } catch (error) {
      console.error("Error fetching booking details:", error);
      setLoading(false)
      setError('Error fetching booking details:',error)
    }
  };

  const GetAnouncement = () => {
    try {
      const hasPopupBeenShown = localStorage.getItem("announcementPopupShown");

      if (!hasPopupBeenShown) {
        fetch(`${baseUrl}/announcement/get`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Failed to fetch announcement");
            }
            return response.json();
          })
          .then((data) => {
            if (data.isSuccess) {
              const today = new Date();
              const formattedToday =
                today.getDate().toString().padStart(2, "0") +
                "-" +
                (today.getMonth() + 1).toString().padStart(2, "0") +
                "-" +
                today.getFullYear();

              const todayAnnouncements = data.result.filter((announcement) => {
                return announcement.publishDate === formattedToday;
              });

              if (todayAnnouncements.length > 0) {
                setAnnouncement(todayAnnouncements);
                setAnnouncePopup(true);
                localStorage.setItem("announcementPopupShown", "true");
              }
              setLoading(false)
            }
          })
          .catch((err) => {
            console.error("Error fetching announcement:", err);
            setLoading(false)
            setError('Error fetching announcement:',err)
          });
      }
    } catch (error) {
      console.log(error);
      setLoading(false)
      setError('UnCaught fetching announcement:',error)
    }
  };

  const nextAnnouncement = () => {
    setCurrentAnnouncementIndex((prev) =>
      prev === Announcement.length - 1 ? 0 : prev + 1
    );
  };

  const previousAnnouncement = () => {
    setCurrentAnnouncementIndex((prev) =>
      prev === 0 ? Announcement.length - 1 : prev - 1
    );
  };

  const GetProject = () => {
    try {
      fetch(`${baseUrl}/home/managerProjects`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch announcement");
          }
          return response.json();
        })
        .then((data) => {
          if (data.isSuccess) {
            setProject(data?.result);
            setLoading(false)
          }
        }).catch((err)=>{
          console.error("Error fetching project:", err);
          setLoading(false)
          setError('Error fetching project:',err)
        })
    } catch (error) {
      console.log(error);
      setLoading(false)
      setError('UnCaught fetching project:',error)
    }
  };

  useEffect(() => {
    GetAnouncement();
    fetchUserTask();
    GetProject();
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem("announcementPopupShown");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  if(loading){
    return <div className="flex items-center justify-center h-screen w-full"><Loader /></div>;
  }

  if(error){
    return <div className="flex items-center justify-center h-screen w-full">{error.message}</div>;
  }

  return (
    <>
      <div  className="w-full min-h-screen h-auto ">
        <div
          className={` ${
            i18n.language === "ar"
              ? "justify-center lg:justify-end"
              : "justify-center lg:justify-start"
          }`}
        >
          <CardOne reloadCounter={reloadCounter} />
        </div>
        <div className="lg:hidden mt-5">
          <QuickCard />
        </div>

        {announcePopup && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div
              className="fixed  inset-0 bg-black bg-opacity-50"
              onClick={onClose}
            />

            <div className="bg-white h-[60vh] rounded-lg shadow-xl p-6 max-w-md w-full mx-4 relative z-10">
              <button
                onClick={onClose}
                className="absolute text-[12px] lg:text-xl top-4 right-4 text-gray-500 hover:text-gray-700 transition-colors"
              >
                <X size={20} />
              </button>

              <div className="lg:space-y-4 space-y-2">
                <div className="flex items-center justify-between">
                  <h2 className=" text-[14px] lg:text-xl font-semibold text-gray-900">
                    Announcement
                  </h2>
                </div>

                <div className="w-full h-[100px] lg:h-48 relative">
                  <Lottie
                    animationData={announcementAnimation}
                    loop={true}
                    autoplay={true}
                    className="w-full h-full object-contain"
                  />

                  {Announcement.length > 1 && (
                    <>
                      <button
                        onClick={previousAnnouncement}
                        className="absolute left-0 top-1/2 -translate-y-1/2 bg-white/80 hover:bg-white p-1 rounded-full shadow-md"
                      >
                        <svg
                          className="w-6 h-6"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M15 19l-7-7 7-7"
                          />
                        </svg>
                      </button>
                      <button
                        onClick={nextAnnouncement}
                        className="absolute right-0 top-1/2 -translate-y-1/2 bg-white/80 hover:bg-white p-1 rounded-full shadow-md"
                      >
                        <svg
                          className="w-6 h-6"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M9 5l7 7-7 7"
                          />
                        </svg>
                      </button>
                    </>
                  )}
                </div>

                {Announcement.length > 1 && (
                  <div className="flex justify-center gap-2   py-2">
                    {Announcement.map((_, index) => (
                      <button
                        key={index}
                        onClick={() => setCurrentAnnouncementIndex(index)}
                        className={`w-2 h-2 rounded-full transition-colors ${
                          index === currentAnnouncementIndex
                            ? "bg-blue-600"
                            : "bg-gray-300"
                        }`}
                      />
                    ))}
                  </div>
                )}

                <h1 className="text-[14px] lg:text-lg text-gray-900">
                  {Announcement[currentAnnouncementIndex]?.title}
                </h1>
                <p className="text-[14px] lg:text-lg text-gray-600">
                  {Announcement[currentAnnouncementIndex]?.shortDesc}
                </p>

                <div className="flex justify-end  ">
                  <Link
                    to={`/home/announcement/announcementdetail?id=${Announcement[currentAnnouncementIndex]?.announcementId}`}
                  >
                    <button className="relative overflow-hidden flex items-center justify-center gap-2 px-2 py-1 lg:px-5 lg:py-2 font-bold text-white bg-blue-600 border-3 border-white  rounded-full shadow-md outline-none transition-transform duration-300 ease-in-out hover:scale-105 hover:border-white/90">
                      Know More
                      <svg
                        className="w-6 h-6 transition-transform duration-300 ease-in-out group-hover:translate-x-1"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <div className="absolute top-0 left-0 w-[100px] h-full bg-gradient-to-r from-transparent via-white/80 to-transparent opacity-60 -translate-x-[100px] group-hover:animate-shine" />
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* <-------------------------------------New meeting design-------------------------------------------> */}

        <div
          style={component}
          className="p-3 w-full  flex-cols items-center justify-center rounded-lg mt-5 "
        >
          <div className="flex items-start justify-between">
            <div>
              <div className="flex  items-center gap-2 ">
                <h1
                  className="text-[14px] lg:text-[16px] font-semibold"
                  style={blacktext}
                >
                  Today Meeting
                </h1>
                <h1 className="w-[20px] bg-[#EBE9FE] text-[#1552b2] text-[12px] rounded-[4px] h-[20px] flex  items-center justify-center">
                  {meetingInfo.length}
                </h1>
              </div>
              <p className="text-[12px] lg:text-[14px]" style={bluetext}>
                Your schedule for the day
              </p>
            </div>
            {meetingInfo.length > 0 && (
              <Link to="/home/meeting">
                <p
                  style={bluetext}
                  className=" text-[12px] lg:text-[14px] font-[600] cursor-pointer "
                >
                  {t("seemore")}
                </p>
              </Link>
            )}
          </div>
          <div className="">
            {meetingInfo.length > 0 ? (
              <div
                className={`flex gap-4 xl:gap-10  scroll-container ${
                  i18n.language === "ar" ? "lg:justify-end" : "lg:justify-start"
                }`}
              >
                <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
                  {meetingInfo.map((meet) => (
                    <Link
                      key={meet?.meetingId}
                      to={`/home/meeting/details?meetingId=${meet?.meetingId}`}
                    >
                    
                      <MeetingCard meetingInfos={meet} key={meet?.meetingId} styleKey='interior' />
                    </Link>
                  ))}
                </div>
              </div>
            ) : (
              <div className="flex-col place-items-center">
                <NoMeet />
                <p
                  style={blacktext}
                  className="text-[14px] lg:text-[16px] text-center mt-3"
                >
                  {t("noactionreq")}
                </p>
                <p className="text-center text-[12px] xl:text-[14px]">
                It looks like you don’t have any meetings scheduled at the moment.
                </p>
              </div>
            )}
          </div>
        </div>

        {/* <-------------------------------------New task design----------------------------------------------> */}

        <div
          style={component}
          className="p-3 w-full  flex-cols items-center justify-center mt-5 rounded-lg"
        >
          <div className="flex items-start justify-between">
            <div>
              <div className="flex  items-center gap-2 ">
                <h1
                  className="text-[14px] lg:text-[16px] font-semibold"
                  style={blacktext}
                >
                  Today Tasks
                </h1>
                <h1 className="w-[20px] font-bold bg-[#EBE9FE] text-[#1552b2] text-[12px] rounded-[4px] h-[20px] flex  items-center justify-center">
                  {taskInfo.length}
                </h1>
              </div>
              <p className="text-[12px] lg:text-[14px]" style={bluetext}>
                The tasks assigned to you for today
              </p>
            </div>
            {taskInfo.length > 0 && (
              <Link to="/home/task">
                <p
                  style={bluetext}
                  className=" text-[12px] lg:text-[14px] font-[600] cursor-pointer "
                >
                  {t("seemore")}
                </p>
              </Link>
            )}
          </div>
          <div className="">
            {taskInfo.length > 0 ? (
              <div
                className={`flex gap-4 xl:gap-10 scroll-container ${
                  i18n.language === "ar" ? "lg:justify-end" : "lg:justify-start"
                }`}
              >
                <div className="mt-2 w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
                  {taskInfo.map((task) => (
                    <Link
                      key={task._id}
                      to={`/home/task/taskdetail?taskId=${task?.taskId}`}
                    >
                      <div style={interior} className=" rounded-xl p-2 ">
                        <div className="flex-cols items-center ">
                          <div className="flex items-center justify-between text-[14px] mb-3">
                            <h1
                              style={blacktext}
                              className="w-[170px] min-[425px]:w-[230px] xl:w-[200px] break-all text-[14px] xl:text-[16px] font-semibold"
                            >
                              {task?.title}
                            </h1>
                            <div className="flex items-center gap-1">
                              Due date
                            </div>
                          </div>
                          <div>
                            <div className="flex justify-between  mt-2">
                              <div className="flex">
                                <div className="self-end flex lg:gap-1 gap-3 xl:gap-3">
                                  <div>
                                    <div
                                      className={`w-auto h-[20px] px-2 py-2 flex items-center justify-center rounded-[20px] ${
                                        task.status === "Pending"
                                          ? "bg-[#9D9A9A] text-white "
                                          : task.status === "In Progress"
                                          ? "bg-[#FF6C3B]  text-white "
                                          : task.status === "Completed"
                                          ? "bg-[#D6F4E3] text-[#1F7D66] fill-[#1F7D66]"
                                          : ""
                                      }`}
                                    >
                                      <svg
                                        width="10"
                                        height="11"
                                        viewBox="0 0 10 11"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M4.99967 1.3335C2.70384 1.3335 0.833008 3.20433 0.833008 5.50016C0.833008 7.796 2.70384 9.66683 4.99967 9.66683C7.29551 9.66683 9.16634 7.796 9.16634 5.50016C9.16634 3.20433 7.29551 1.3335 4.99967 1.3335ZM6.81217 6.98766C6.75384 7.08766 6.64967 7.14183 6.54134 7.14183C6.48717 7.14183 6.43301 7.12933 6.38301 7.096L5.09134 6.32516C4.77051 6.1335 4.53301 5.71266 4.53301 5.34183V3.6335C4.53301 3.46266 4.67467 3.321 4.84551 3.321C5.01634 3.321 5.15801 3.46266 5.15801 3.6335V5.34183C5.15801 5.49183 5.28301 5.71266 5.41217 5.78766L6.70384 6.5585C6.85384 6.646 6.90384 6.83766 6.81217 6.98766Z"
                                          fill="currentColor"
                                        />
                                      </svg>

                                      <p className="ml-1 text-[8px] md:text-[10px]">
                                        {task.status === "Pending"
                                          ? "Initiated"
                                          : task.status === "In Progress"
                                          ? "In Progress"
                                          : task.status === "Completed"
                                          ? "Completed"
                                          : ""}
                                      </p>
                                    </div>
                                  </div>

                                  <div>
                                    <div
                                      className={`w-[50px] lg:w-[40px] xl:w-[50px] h-[20px] flex items-center justify-center rounded-[20px] ${
                                        task.priority === 2 ||
                                        task.priority === "2"
                                          ? "bg-[#F95555]"
                                          : task.priority === 1 ||
                                            task.priority === "1"
                                          ? "bg-[#F83600]"
                                          : "bg-[#1552B2]"
                                      }`}
                                    >
                                      <p className="text-[#F8F6FF] text-[10px]">
                                        {task.priority === 2 ||
                                        task.priority === "2"
                                          ? "High"
                                          : task.priority === 1 ||
                                            task.priority === "1"
                                          ? "Medium"
                                          : "Low"}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <p>{formatTime(task.to)}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            ) : (
              <div className="flex-col items-center place-items-center">
               <NoTask />
                <p
                  style={blacktext}
                  className="text-[14px] xl:text-[16px] text-center mt-3"
                >
                  {t("noactionreq")}
                </p>
                <p className="text-center text-[12px] xl:text-[14px]">
                  It looks like you don't have any tasks assigned to you right
                  now.
                </p>
              </div>
            )}
          </div>
        </div>

        {/* <-------------------------------------New project design-------------------------------------------> */}

        <div style={component} className="p-3 w-full  flex-cols items-center justify-center mt-5 rounded-lg">
          <div className="flex items-start justify-between">
            <div>
              <div className="flex  items-center gap-2 ">
                <h1
                  className="text-[14px] lg:text-[16px] font-semibold"
                  style={blacktext}
                >
                  Recent Projects
                </h1>
                <h1 className="w-[20px] bg-[#EBE9FE] text-[#1552b2] text-[12px] rounded-[4px] h-[20px] flex  items-center justify-center">
                  {project.length}
                </h1>
              </div>
              <p className="text-[12px] lg:text-[14px] " style={bluetext}>
                The Projects assigned to you.
              </p>
            </div>
            {project.length > 0 && (
              <Link to="/home/projectdashboard/allproject">
                <p
                  style={bluetext}
                  className="text-[10px] lg:text-[14px] font-[600] cursor-pointer "
                >
                  {t("seemore")}
                </p>
              </Link>
            )}
          </div>
          {
            project.length > 0 ? ( 
              <div
                className={` ${
                  i18n.language === "ar" ? "lg:justify-end" : "lg:justify-start"
                }`}
              >
              <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3  gap-2 md:gap-5 mt-2">
              {project.map((projectItem) => (
                <Link
                  key={projectItem.projectId}
                  to={`/home/projectdashboard/allproject/viewproject?id=${projectItem.projectId}`}
                >
                  <ProjectCard project={projectItem} styleKey="interior" />
                </Link>
              ))}
            </div>
            </div>
            ) : (
              <div>
                <div className="flex-col items-center place-items-center">
               <NoTask />
                <p
                  style={blacktext}
                  className="text-[14px] xl:text-[16px] text-center mt-3"
                >
                  {t("noactionreq")}
                </p>
                <p className="text-center text-[12px] xl:text-[14px]">
                  It looks like you don't have any Projects assigned to you right
                  now.
                </p>
              </div>
              </div>
            ) 
          }
         
        </div>
        <div className="w-full">
          <VoiceBot />
        </div>
      </div>
    </>
  );
}

export default Dashboard;
