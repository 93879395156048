import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function HeaderChat({ socketConnected }) {
  const [username, setUsername] = useState("Guest");

  useEffect(() => {
    const storedUsername = sessionStorage.getItem("username");
    if (storedUsername) {
      setUsername(storedUsername);
    }
  }, []);

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <header className="lg:hidden relative w-full top-0 flex items-center h-[70px] bg-[#1552B2] ">
      <button
        onClick={handleGoBack}
        className="flex items-center justify-center  h-[20px] rounded-xl  text-white w-[40px]"
      >
        <span class="material-symbols-outlined !text-[16px] ml-1 !font-bold">
          arrow_back_ios
        </span>
      </button>
      <h2 className="w-full text-center text-lg font-semibold text-white">
        {username}
      </h2>

 
    </header>
  );
}

export default HeaderChat;
