import React, { useEffect, useState, useCallback, useRef } from "react";
import Clock from "react-live-clock";
import { useTheme } from "./Theme/Themecontext";
import { useTranslation } from "react-i18next";
import { jwtDecode } from "jwt-decode";
import CeoDashboardCircle from "./CeoDashboardCircle";

const CardOne = ({ reloadCounter }) => {
  const { t, i18n } = useTranslation();
  const { styles } = useTheme();
  const { component, bluetext } = styles;

  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;
  const date = useRef(new Date());

  const [userclkInfo, setUserclkInfo] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const [workingHours, setWorkingHours] = useState("00h : 00m");
  const [clockInInterval, setClockInInterval] = useState(null);

  const [data, setData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [lat, setLat] = useState(0);
  const [lon, setLon] = useState(0);
  const [attendance, setAttendance] = useState([]);
  // console.log(attendance,"ddd")

  const getLocation = useCallback(
    (lat, lon) => {
      const apiUrl = isChecked ? `${baseUrl}/punch/out` : `${baseUrl}/punch/in`;
      const bodyData = { lat, lon };

      try {
        const token = localStorage.getItem("token");
        fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(bodyData),
        })
          .then((response) => response.json())
          .then((data) => {
            if(data.isSuccess){
              fetchUserclkInfo();
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } catch (error) {
        console.error("Error:", error);
      }
    },
    [isChecked, baseUrl]
  );

  const fetchUserclkInfo = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`${baseUrl}/punch/details`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        
        // Check if data or data.result is null or undefined
        if (!data || !data.result) {
          console.log("No clock data available");
          return;
        }
        
        const { isPunch, overAllTime, punchIn, punchOut } = data.result;
  
        setIsChecked(isPunch);
        setUserclkInfo(data.result);
  
        if (punchOut) {
          const finalMinutes = calculateMinutesBetween(
            new Date(punchIn),
            new Date(punchOut)
          );
          finalizeWorkingHours(finalMinutes, overAllTime);
          clearInterval(clockInInterval);
          setClockInInterval(null);
        }
  
        if (punchIn && !punchOut) {
          const initialMinutes = calculateMinutesBetween(
            new Date(punchIn),
            new Date()
          );
          finalizeWorkingHours(initialMinutes, overAllTime);
          startRealTimeUpdates(new Date(punchIn), overAllTime);
        }
      } else {
        throw new Error("Failed to fetch clock details");
      }
    } catch (error) {
      console.error("Error fetching clock details:", error);
    }
  }, []);

  const calculateMinutesBetween = (start, end) => {
    const difference = end - start;
    return Math.floor(difference / (1000 * 60));
  };

  const formatMinutesToHours = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours.toString().padStart(2, "0")}h : ${minutes
      .toString()
      .padStart(2, "0")}m`;
  };

  const finalizeWorkingHours = (currentSessionMinutes, overAllTime) => {
    let totalMinutes = currentSessionMinutes;

    if (overAllTime && overAllTime.length > 0) {
      overAllTime.forEach(({ punchIn, punchOut }) => {
        if (punchIn && punchOut) {
          totalMinutes += calculateMinutesBetween(
            new Date(punchIn),
            new Date(punchOut)
          );
        }
      });
    }

    setWorkingHours(formatMinutesToHours(totalMinutes));
  };

  const startRealTimeUpdates = (punchInTime, overAllTime) => {
    if (clockInInterval) clearInterval(clockInInterval);

    const interval = setInterval(() => {
      const currentMinutes = calculateMinutesBetween(punchInTime, new Date());

      finalizeWorkingHours(currentMinutes, overAllTime);
    }, 60000);

    setClockInInterval(interval);
  };

  useEffect(() => {
    fetchUserclkInfo();
  }, []); // Only run on mount

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };


  const handleConfirmation = (confirmation) => {
    setIsModalOpen(false);

    if (confirmation) {
      getLocation(lat, lon);
    } else {
      console.log("User canceled the confirmation.");
    }
  };

  const getconfirmation = useCallback(
    (lat, lon) => {
      const apiUrl = `${baseUrl}/punch/confirmation`;
      const bodyData = { lat, lon };

      try {
        const token = localStorage.getItem("token");
        fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(bodyData),
        })
          .then((response) => response.json())
          .then((data) => {
            setData(data.result);
            setLat(lat);
            setLon(lon);
            setIsModalOpen(true);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } catch (error) {
        console.error("Error:", error);
      }
    },
    [baseUrl]
  );

  const handleCheckboxChange = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          getconfirmation(position.coords.latitude, position.coords.longitude);
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            alert(
              "Please turn on browser location settings to clock in & clock out."
            );
            // getLocation(0.0, 0.0);
          } else {
            console.error("Error getting location:", error);
            alert(
              "Error getting location. Please ensure location services are enabled."
            );
            // getLocation(0.0, 0.0);
          }
        }
      );
    } else {
      alert("Geolocation is not supported by your browser.");
      // getLocation(0.0, 0.0);
    }
  };

  useEffect(() => {
    fetchUserclkInfo();
  }, [fetchUserclkInfo]);

  const token = localStorage.getItem("token");
  const userInfo = jwtDecode(token);

  useEffect(() => {
    const fetchAttendance = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(`${baseUrl}/ceo/getMasterInfo`, {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          const data = await response.json();
          setAttendance(data.result, "ceo das");
          console.log(data, "work force fetch");
        } else {
          throw new Error("Failed to fetch booking details");
        }
      } catch (error) {
        console.error("Error fetching booking details:", error);
      }
    };

    fetchAttendance();
  }, [baseUrl]);

  return (
    <>
      <section className={`${i18n.language === 'ar' ? 'text-right' : 'text-left'} ${i18n.language === 'ar' ? 'rtl' : 'ltr'}`}
        dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
 


        <div className={`md:flex md:gap-5 xl:gap-10 ${i18n.language === 'ar' ? 'text-right' : 'text-left'} ${i18n.language === 'ar' ? 'rtl' : 'ltr'}`}
          dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
          <div
            style={component}
            className={`p-2 rounded-[10px] mb-3 md:mb-0  h-[155px] md:w-[300px] lg:w-[350px]`}
          >
            {/* Displaying Working Hours */}
            <div className="p-2 w-full flex flex-row  justify-between items-center">
              <Clock
                className={`!text-[13px] md:!text-[14px] font-semibold`}
                format={"hh:mm:ss A"}
                style={bluetext}
                ticking={true}
              />
              <div className="flex !items-center gap-2">
                <p className={`!text-[13px] md:text-[14px] font-semibold`} style={bluetext}>
                  {formatDate(date.current)}
                </p>
              </div>
            </div>

            <div className="flex justify-center items-center mt-2 ">
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
                id="toggle"
                className="sr-only peer"
              />

              <label htmlFor="toggle" className="relative">
                <div
                  className={` ${
                    isChecked
                      ? "border-[.5px] cursor-pointer h-[52px] w-[250px] md:h-[52px] bg-gray-100  rounded-[10px] flex items-center px-1"
                      : " border-[.5px] cursor-pointer h-[52px] w-[250px] md:h-[52px] bg-gray-100    rounded-[10px] flex items-center px-1"
                  }`}
                >
                  <div
                    className={` 
                h-[40px] w-[40px] md:h-[45px] md:w-[45px] rounded-[10px] cursor-pointer absolute transition-all duration-500 grid place-items-center
                ${
                  isChecked
                    ? i18n.language === "ar"
                      ? "left-[5px] !bg-[#ffff]" // Arabic - User - Checked
                      : "left-[200px] !bg-[#ffff] rounded-[10px]" // English - User - Checked
                    : i18n.language === "ar"
                    ? "right-[5px] !bg-[#ffff]" // Arabic - User - Unchecked
                    : "left-[4px] !bg-[#ffff]" // English - User - Unchecked
                }`}
                  >
                    {isChecked ? (
                      <span
                        className={`text-[#ffff] bg-red-600 p-2 px-3 font-bold rounded-md flex ${
                          i18n.language === "ar"
                            ? "flex-row-reverse"
                            : "flex-row"
                        }`}
                      >
                        {i18n.language === "ar" ? (
                          <span class="material-symbols-outlined">
                            keyboard_double_arrow_right
                          </span>
                        ) : (
                          <span class="material-symbols-outlined">
                            keyboard_double_arrow_left
                          </span>
                        )}
                      </span>
                    ) : (
                      <span
                        className={`text-[#ffff] bg-[#197619] p-2 px-3 font-bold rounded-md flex ${
                          i18n.language === "ar"
                            ? "flex-row-reverse"
                            : "flex-row"
                        }`}
                      >
                        {i18n.language === "ar" ? (
                          <span class="material-symbols-outlined">
                            keyboard_double_arrow_left
                          </span>
                        ) : (
                          <span class="material-symbols-outlined">
                            keyboard_double_arrow_right
                          </span>
                        )}
                      </span>
                    )}
                  </div>
                </div>
                <span
                  className={`absolute w-[170px] text-center top-1/2 transform -translate-x-1/2  -translate-y-1/2 cursor-pointer ${
                    isChecked
                      ? i18n.language === "ar"
                        ? "left-[130px] md:left-[130px] text-red-600 text-[12px]"
                        : "left-[90px] md:left-[100px] text-red-600 top-1/2 text-[12px]"
                      : i18n.language === "ar"
                      ? "left-[110px] md:left-[110px] text-[#197619] text-[12px] top-1/2"
                      : "left-[140px] md:left-[140px] text-[#197619] text-[12px] top-1/2"
                  }`}
                >
                  {isChecked ? t("Swipetoclockout") : t("Swipetoclockin")}
                </span>
              </label>
            </div>

            <div className={`flex flex-row gap-1 justify-center mb-4 mt-1`}>
              <div
                className={`flex justify-center items-center text-[14px] md:text-[18px] font-bold w-full md:w-[300px] lg:w-[350px] xl:w-[360px] h-[30px] p-0.5 rounded-[20px]  ${
                  i18n.language === "ar" ? "text-right" : "text-left"
                } ${i18n.language === "ar" ? "rtl" : "ltr"}`}
                dir={i18n.language === "ar" ? "rtl" : "ltr"}
              >
                <span
                  className={`text-[#EA001B] text-[14px] ${
                    i18n.language === "en" ? "block" : "hidden"
                  }`}
                >
                  {t("workingHours")} - {workingHours} {t("today")}
                </span>
                <span
                  className={`flex flex-row gap-2 justify-center text-center text-white ${
                    i18n.language === "ar" ? "block text-right" : "hidden"
                  } ${i18n.language === "ar" ? "rtl" : "ltr"}`}
                  dir={i18n.language === "ar" ? "rtl" : "ltr"}
                >
                  {" "}
                  <p>{t("today")}</p> {workingHours} - {t("workingHours")}
                </span>
              </div>
            </div>
            {isModalOpen && (
              <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-70 p-5">
                <div
                  className={`bg-[#ffffff] border border-[#ffffff] rounded-[20px] lg:w-[500px] xl:w-[500px] min-w-[250px] p-2 shadow-lg transition-transform transform scale-100 hover:scale-105  ${
                    i18n.language === "ar" ? "text-right" : "text-left"
                  } ${i18n.language === "ar" ? "rtl" : "ltr"}`}
                  dir={i18n.language === "ar" ? "rtl" : "ltr"}
                >
                  <p
                    onClick={() => handleConfirmation(false)}
                    className="flex justify-end"
                  >
                    <span className="material-symbols-outlined text-[#1552B2] hover:text-[#0e1829] cursor-pointer !font-bold px-2">
                      close
                    </span>
                  </p>

                  <div className="p-4">
                    <h2 className="text-xl font-semibold text-[#222222] text-center">
                      {isChecked ? t("clockOutConfirm") : t("clockInConfirm")}
                    </h2>

                    <h2 className="text-[14px] font-normal text-center ">
                      {isChecked
                        ? t("Are you sure you want to Clock Out?")
                        : t("Are you sure you want to Clock In?")}
                    </h2>
                    <p className="text-[24px] font-semibold text-[#222222] text-center">
                      {new Date(data?.punchTime).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",

                        hour12: true,
                      })}
                    </p>
                    <p className="text-[14px] text-[#222222] text-center ">
                      {data?.message}
                    </p>
                    <p className="text-[14px] text-[#222222] text-center ">
                      {new Date(data?.punchDate).toLocaleDateString("en-GB", {
                        month: "long",
                        day: "2-digit",
                        year: "numeric",
                      })}
                    </p>
                    <p className="text-[16px] font-semibold text-[#222222] text-center ">
                      {data?.location}
                    </p>

                    <div className="mt-4">
                      <div className="flex justify-center gap-4 mt-4 ">
                        <button
                          onClick={() => handleConfirmation(false)}
                          className="bg-[#1552B2]  flex gap-2 justify-center border border-[#1552B2]  font-bold py-3 w-[100px] rounded-lg shadow-md hover:bg-[#ffffff] hover:border-[#1552B2] text-[#ffffff] hover:text-[#1552B2] transition duration-200"
                        >
                          <p className=" ">No</p>
                        </button>
                        <button
                          onClick={() => handleConfirmation(true)}
                          className="bg-[#1552B2]  flex gap-2 justify-center border border-[#1552B2]  font-bold py-3 w-[100px] rounded-lg shadow-md hover:bg-[#ffffff] hover:border-[#1552B2] text-[#ffffff] hover:text-[#1552B2] transition duration-200"
                        >
                          <p className="">Yes</p>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {userInfo && (userInfo.role === 0 || userInfo.role === 1) && (
            <CeoDashboardCircle
              theme = {component}
              className="align-middle"
              total={attendance[0]?.totalUser}
              values={[
                attendance[0]?.totalUserInoff,
                attendance[0]?.totalUserRemote,
                attendance[0]?.totalUserLeave,
              ]}
              sum={
                attendance[0]?.totalUserInoff + attendance[0]?.totalUserRemote
              }
            />
          )}
        </div>
      </section>
    </>
  );
};

export default CardOne;

