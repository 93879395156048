import React from "react";
import { useAlertViewStore } from "../store/index";

const Alert = () => {
    const msgList = useAlertViewStore(state => state.msgList);
    const { closeMsg } = useAlertViewStore();

    if (msgList.length === 0) return null;

    return (
        <div className="toast fixed top-16 right-2 sm:top-20 sm:right-5 text-center justify-center items-center w-[90%] sm:w-[350px] max-w-[350px] z-[5000] flex flex-col gap-2 sm:gap-3">
            {msgList.map(a => {
                let clsName = "alert min-h-[48px] w-full transition-all duration-300 ease-in-out";
                if (a.alertType === "success") {
                    clsName = "alert bg-[#d3fcb2] text-[#222222] border-0 p-2 sm:p-3 flex justify-between items-center rounded-lg shadow-lg min-h-[40px] sm:min-h-[48px] w-full";
                } else if (a.alertType === "warning") {
                    clsName = "alert alert-warning text-white border-0 p-2 sm:p-3 flex justify-between items-center rounded-lg shadow-lg min-h-[40px] sm:min-h-[48px] w-full";
                } else if (a.alertType === "error") {
                    clsName = "alert bg-red-500 text-white border-0 p-2 sm:p-3 flex justify-between items-center rounded-lg shadow-lg min-h-[40px] sm:min-h-[48px] w-full";
                }
                return (
                    <div 
                        className={clsName} 
                        key={a.id}
                        style={{
                            animation: 'slideIn 0.8s ease-out'
                        }}
                    >
                        <div className="flex items-center gap-1 sm:gap-2 flex-1">
                            {a.alertType === "error" ? (
                                <svg 
                                    xmlns="http://www.w3.org/2000/svg" 
                                    className="h-4 w-4 sm:h-5 sm:w-5 min-w-[16px] sm:min-w-[20px]" 
                                    fill="none" 
                                    viewBox="0 0 24 24" 
                                    stroke="currentColor"
                                >
                                    <path 
                                        strokeLinecap="round" 
                                        strokeLinejoin="round" 
                                        strokeWidth={2} 
                                        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" 
                                    />
                                </svg>
                            ) : 
                            a.alertType === "success" ? (
                                <svg 
                                    xmlns="http://www.w3.org/2000/svg" 
                                    className="h-4 w-4 sm:h-5 sm:w-5 min-w-[16px] sm:min-w-[20px]" 
                                    viewBox="0 -960 960 960" 
                                    fill="#086300"
                                >
                                    <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>
                            ): <></>}
                            <span className="text-xs sm:text-sm font-medium flex-1 text-left">{a.alertMsg}</span>
                        </div>
                        <button
                            className="ml-1 sm:ml-2 inline-flex text-current opacity-70 hover:opacity-100 focus:outline-none"
                            onClick={() => closeMsg(a.id)}
                        >
                            <span className="material-icons material-symbols-outlined cursor-pointer !text-[14px] sm:!text-[16px]">
                                close
                            </span>
                        </button>
                    </div>
                );
            })}
            <style jsx global>{`
                @keyframes slideIn {
                    from {
                        transform: translateX(100%);
                        opacity: 0;
                    }
                    to {
                        transform: translateX(0);
                        opacity: 1;
                    }
                }
                
                @media (max-width: 640px) {
                    @keyframes slideIn {
                        from {
                            transform: translateY(-20px);
                            opacity: 0;
                        }
                        to {
                            transform: translateY(0);
                            opacity: 1;
                        }
                    }
                }
            `}</style>
        </div>
    );
};

export default Alert;