import React from 'react';
import { useTheme } from "../components/Theme/Themecontext";

const CircularProgress = ({ progress }) => {
    const { styles } = useTheme();
    const { blacktext } = styles;

    const radius = 38;
    const stroke = 6; // Increased from 5 to 10
    const normalizedRadius = radius - stroke * 2;
    const circumference = normalizedRadius * 2 * Math.PI;
    const strokeDashoffset = circumference - (progress / 100) * circumference;

    return (
        <svg
            height={radius * 2}
            width={radius * 2}
            className="circular-progress">
            <circle
                stroke="#676767"
                fill="transparent"
                strokeWidth={stroke}
                strokeLinecap="round"
                r={normalizedRadius}
                cx={radius}
                cy={radius}
            />
            {progress > 0 && (
                <circle
                    stroke="#2051E5"
                    fill="transparent"
                    strokeWidth={stroke}
                    strokeDasharray={circumference + ' ' + circumference}
                    strokeDashoffset={strokeDashoffset}
                    strokeLinecap="round"
                    r={normalizedRadius}
                    cx={radius}
                    cy={radius}
                    className="progress"
                    style={{
                        transition: 'stroke-dashoffset 0.35s',
                        transform: 'rotate(-90deg)',
                        transformOrigin: '50% 50%'
                    }}
                />
            )}
            <text
                style={blacktext}
                x="50%"
                y="50%"
                dy=".3em"
                textAnchor="middle"
                fontSize="12px"
            >
                {`${progress}%`}
            </text>
        </svg>
    );
}

export default CircularProgress;